// polaroidImageProcessor.js

function calculateCoverScaleAndPosition(imageWidth, imageHeight, targetWidth, targetHeight) {
    const scale = Math.max(targetWidth / imageWidth, targetHeight / imageHeight);
    const scaledWidth = imageWidth * scale;
    const scaledHeight = imageHeight * scale;
    const offsetX = (targetWidth - scaledWidth) / 2;
    const offsetY = (targetHeight - scaledHeight) / 2;
    return { scale, offsetX, offsetY };
}



export function createPolaroidImage(imageSrc, text) {
    return new Promise(async (resolve, reject) => {
        await document.fonts.load('30px "Speedy Marker"');
        await document.fonts.load('10px "Messinasans webfont"');

        const paperTextureIndex = Math.floor(Math.random() * 56) + 1;
        const paperTextureSrc = `./Polaroid-Layers/Paper/paper-${("0" + paperTextureIndex).slice(-2)}.webp`;

        const grainImageIndex = Math.floor(Math.random() * 7) + 1;
        const grainImageSrc = `./Polaroid-Layers/Grain/grain-0${grainImageIndex}.webp`;

        const lensDirtImageIndex = Math.floor(Math.random() * 36) + 1;
        const lensDirtImageSrc = `./Polaroid-Layers/LensDirt/lensdirt-${("0" + lensDirtImageIndex).slice(-2)}.webp`;

        const watermarkSrc = './Drip_Logo_64.png';

        const loadImage = src => new Promise((res, rej) => {
            const img = new Image();
            img.onload = () => res(img);
            img.onerror = rej;
            img.crossOrigin = "anonymous";
            img.src = src;
        });

        try {
            const [loadedImage, loadedPaperTexture, loadedGrainImage, loadedLensDirtImage, loadedWatermark] = await Promise.all([
                loadImage(imageSrc),
                loadImage(paperTextureSrc),
                loadImage(grainImageSrc),
                loadImage(lensDirtImageSrc),
                loadImage(watermarkSrc)
            ]);

            const targetHeight = 800;
            const aspectRatio = loadedImage.width / loadedImage.height;
            const scaledWidth = targetHeight * aspectRatio;

            const borderSize = 30;
            const maxTextWidth = scaledWidth;
            const canvasTemp = document.createElement("canvas");
            const contextTemp = canvasTemp.getContext("2d");
            contextTemp.font = '30px "Speedy Marker"';
            let fontSize = 30;
            let textWidth = contextTemp.measureText(text).width;
            if (textWidth > maxTextWidth) {
                fontSize *= maxTextWidth / textWidth;
                contextTemp.font = `${fontSize}px "Speedy Marker"`;
                textWidth = contextTemp.measureText(text).width;
            }
            const textHeight = fontSize;
            const textAreaHeight = textHeight + 15;

            const canvas = document.createElement("canvas");
            canvas.width = scaledWidth + borderSize * 2;
            canvas.height = targetHeight + borderSize + textAreaHeight + 30;

            
            const context = canvas.getContext("2d");
            
            context.fillStyle = "white";
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.filter = 'contrast(110%)';

            context.drawImage(loadedImage, borderSize, borderSize, scaledWidth, targetHeight);

            context.filter = 'none';

            const { scale: grainScale, offsetX: grainOffsetX, offsetY: grainOffsetY } = calculateCoverScaleAndPosition(
                loadedGrainImage.width, loadedGrainImage.height, canvas.width, canvas.height
            );

            context.globalCompositeOperation = 'multiply';
            context.globalAlpha = 0.35;
            context.drawImage(loadedGrainImage, grainOffsetX, grainOffsetY, loadedGrainImage.width * grainScale, loadedGrainImage.height * grainScale);
            const { scale: lensDirtScale, offsetX: lensDirtOffsetX, offsetY: lensDirtOffsetY } = calculateCoverScaleAndPosition(
                loadedLensDirtImage.width, loadedLensDirtImage.height, canvas.width, canvas.height
            );
            context.globalCompositeOperation = 'color-dodge';
            context.globalAlpha = 0.5;
            context.drawImage(loadedLensDirtImage, lensDirtOffsetX, lensDirtOffsetY, loadedLensDirtImage.width * lensDirtScale, loadedLensDirtImage.height * lensDirtScale);
            context.globalAlpha = 1.0;

            context.globalCompositeOperation = 'source-over';

            context.fillStyle = "black";
            context.font = `${fontSize}px "Speedy Marker"`;
            context.textBaseline = "middle";
            context.textAlign = "center";
            const textX = canvas.width / 2;
            const textY = targetHeight + borderSize + 20 + textHeight / 2;

            context.fillText(text, textX, textY);

            const { scale: paperTextureScale, offsetX: paperTextureOffsetX, offsetY: paperTextureOffsetY } = calculateCoverScaleAndPosition(
                loadedPaperTexture.width, loadedPaperTexture.height, canvas.width, canvas.height
            );

            context.globalCompositeOperation = 'multiply';
            context.drawImage(loadedPaperTexture, paperTextureOffsetX, paperTextureOffsetY, loadedPaperTexture.width * paperTextureScale, loadedPaperTexture.height * paperTextureScale);
            context.globalCompositeOperation = 'source-over';

            const watermarkMargin = 10;
            const userImageWidth = scaledWidth;
            const userImageHeight = targetHeight;
            const watermarkNewHeight = 46;
            const watermarkAspectRatio = loadedWatermark.width / loadedWatermark.height;
            const watermarkNewWidth = watermarkNewHeight * watermarkAspectRatio;
            const watermarkX = borderSize + userImageWidth - watermarkNewWidth - watermarkMargin;
            const watermarkY = borderSize + userImageHeight - watermarkNewHeight - watermarkMargin;
            context.globalAlpha = 0.80;
            context.drawImage(loadedWatermark, watermarkX, watermarkY, watermarkNewWidth, watermarkNewHeight);

            context.globalCompositeOperation = 'source-over';
            context.globalAlpha = 0.8;
            context.fillStyle = "black";
            context.font = '10px "Messinasans webfont"'; // Set font size to 5px and font-family to Arial
            context.textBaseline = "bottom";
            context.textAlign = "center";
            const websiteTextX = canvas.width / 2;
            const websiteTextY = canvas.height - 2; // 2px margin from the bottom
            context.fillText("www.dripcheck.fit", websiteTextX, websiteTextY);

            canvas.toBlob((blob) => {
                const url = URL.createObjectURL(blob);
                resolve(url);
            }, "image/png");
        } catch (error) {
            reject(new Error("Failed to load images"));
        }
    });
}
