import React from 'react'; // Import React

function Background() {
    return (
        <div className="page-wrapper-full-w-outer">
            <div className="image-wrapper-inner">
                <div className="loop-container top">
                    <div className="image-row-wrapper">
                        <div className="single-bg-image _1"></div>
                        <div className="single-bg-image-long _2"></div>
                        <div className="single-bg-image _3"></div>
                        <div className="single-bg-image _4"></div>
                        <div className="single-bg-image-long _5"></div>
                        <div className="single-bg-image _1"></div>
                        <div className="single-bg-image-long _2"></div>
                        <div className="single-bg-image _3"></div>
                        <div className="single-bg-image _4"></div>
                        <div className="single-bg-image-long _5"></div>
                    </div>
                    <div className="image-row-wrapper">
                        <div className="single-bg-image _1"></div>
                        <div className="single-bg-image-long _2"></div>
                        <div className="single-bg-image _3"></div>
                        <div className="single-bg-image _4"></div>
                        <div className="single-bg-image-long _5"></div>
                        <div className="single-bg-image _1"></div>
                        <div className="single-bg-image-long _2"></div>
                        <div className="single-bg-image _3"></div>
                        <div className="single-bg-image _4"></div>
                        <div className="single-bg-image-long _5"></div>
                    </div>
                </div>
                <div className="loop-container upmid">
                    <div className="image-row-wrapper">
                        <div className="single-bg-image _6"></div>
                        <div className="single-bg-image-long _7"></div>
                        <div className="single-bg-image _8"></div>
                        <div className="single-bg-image _9"></div>
                        <div className="single-bg-image-long _10"></div>
                        <div className="single-bg-image _6"></div>
                        <div className="single-bg-image-long _7"></div>
                        <div className="single-bg-image _8"></div>
                        <div className="single-bg-image _9"></div>
                        <div className="single-bg-image-long _10"></div>
                    </div>
                    <div className="image-row-wrapper">
                        <div className="single-bg-image _6"></div>
                        <div className="single-bg-image-long _7"></div>
                        <div className="single-bg-image _8"></div>
                        <div className="single-bg-image _9"></div>
                        <div className="single-bg-image-long _10"></div>
                        <div className="single-bg-image _6"></div>
                        <div className="single-bg-image-long _7"></div>
                        <div className="single-bg-image _8"></div>
                        <div className="single-bg-image _9"></div>
                        <div className="single-bg-image-long _10"></div>
                    </div>
                </div>
                <div className="loop-container botmid">
                    <div className="image-row-wrapper">
                        <div className="single-bg-image _11"></div>
                        <div className="single-bg-image-long _12"></div>
                        <div className="single-bg-image _13"></div>
                        <div className="single-bg-image _14"></div>
                        <div className="single-bg-image-long _15"></div>
                        <div className="single-bg-image _11"></div>
                        <div className="single-bg-image-long _12"></div>
                        <div className="single-bg-image _13"></div>
                        <div className="single-bg-image _14"></div>
                        <div className="single-bg-image-long _15"></div>
                    </div>
                    <div className="image-row-wrapper">
                        <div className="single-bg-image _11"></div>
                        <div className="single-bg-image-long _12"></div>
                        <div className="single-bg-image _13"></div>
                        <div className="single-bg-image _14"></div>
                        <div className="single-bg-image-long _15"></div>
                        <div className="single-bg-image _11"></div>
                        <div className="single-bg-image-long _12"></div>
                        <div className="single-bg-image _13"></div>
                        <div className="single-bg-image _14"></div>
                        <div className="single-bg-image-long _15"></div>
                    </div>
                </div>
                <div className="loop-container bottom">
                    <div className="image-row-wrapper">
                        <div className="single-bg-image _16"></div>
                        <div className="single-bg-image-long _17"></div>
                        <div className="single-bg-image _18"></div>
                        <div className="single-bg-image _19"></div>
                        <div className="single-bg-image-long _20"></div>
                        <div className="single-bg-image _16"></div>
                        <div className="single-bg-image-long _17"></div>
                        <div className="single-bg-image _18"></div>
                        <div className="single-bg-image _19"></div>
                        <div className="single-bg-image-long _20"></div>
                    </div>
                    <div className="image-row-wrapper">
                        <div className="single-bg-image _16"></div>
                        <div className="single-bg-image-long _17"></div>
                        <div className="single-bg-image _18"></div>
                        <div className="single-bg-image _19"></div>
                        <div className="single-bg-image-long _20"></div>
                        <div className="single-bg-image _16"></div>
                        <div className="single-bg-image-long _17"></div>
                        <div className="single-bg-image _18"></div>
                        <div className="single-bg-image _19"></div>
                        <div className="single-bg-image-long _20"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Background;