import React, { useEffect, useRef } from "react";
import Typed from "typed.js";

function Heading() {
    const driphead = useRef(null);
    useEffect(() => {
        const typed = new Typed(driphead.current, {
            strings: ["Clothes", "Outfit", "Shoes", "Fashion", "Style"],
            typeSpeed: 100,
            backSpeed: 40,
            backDelay: 1000,
            loop: true,
            loopCount: Infinity,
            // Specify a single cursor element to prevent multiple cursors
            cursorChar: "|",
        });

        // Destroy Typed instance on unmount
        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <h1 className="main-heading">
            Rate your <br class = "mobile-break" />drip{" "}
            <span className="text-mv" ref={driphead}>
                {" "}
            </span>{" "}
            <br />
            with <span className="styled-inline_text">DripCheck</span>
        </h1>
    );
}

export default Heading;
