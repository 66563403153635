import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js"; // Import Typed.js

import "./css/normalize.css";
import "./css/dripcheck.css";

import DripIcon from "./images/sneakers-shoes.svg"; // Import DripIcon
import DripCheckLogo from "./images/DripCheck_logo.webp"; // Assuming images are in the 'images' folder inside 'src'
import PlaySolidTriangle from "./images/play-solid_triangle.js";
import Background from "./Background";
import { createPolaroidImage } from "./polaroidImageProcessor.js";
import Lottie from "lottie-react";
import CoolEmoji from "./documents/cool-emoji.json";

import Heading from "./Heading.js";
import Footer from "./Footer.js";

function App() {
    // Function for handling file change and drag and drop
    const [fileName, setFileName] = useState("");
    const [isWindowDragOver, setIsWindowDragOver] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [showGeneratedMessage, setShowGeneratedMessage] = useState(false);
    const [base64Image, setBase64Image] = useState(""); // State to store the base64 image
    const [polaroidUrl, setPolaroidUrl] = useState(""); // State to store the polaroid image blob URL
    const [generatedDripRating, setGeneratedDripRating] = useState(1);
    const [generatedPolaroidText, setGeneratedPolaroidText] = useState("");
    const [waitingForGPTResponse, setWaitingForGPTResponse] = useState(false);
    const [uploadText, setUploadText] = useState("📷 Upload Drip"); // Initial upload text


    const typedElement = useRef(null);
    const ratingParagraph = useRef(null); 

    const handleFileChange = (e) => {
        processFile(e.target.files[0]);
    };

    const handleUploadClick = () => {
        document.getElementById("hiddenFileInput").click();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsWindowDragOver(false);
        const file = e.dataTransfer.files[0];
        processFile(file);
    };

    function findRating(text) {
        const ratingPattern = /(\d+(\.\d{1,2})?)\/10/;
        const match = text.match(ratingPattern);
        if (match) {
            const rating = parseFloat(match[1]);
            return Math.floor(rating);
        } else {
            return 1;
        }
    }

    function getRandomSlang() {
        const slangs = ["American", "British"];
        const randomSlang = slangs[Math.floor(Math.random() * slangs.length)];
        return randomSlang;
    }

    function getPolaroidText(generatedPrompt) {
        const outfitRatings = {
            1: [
                "L Bozo, Skill Issue 😬 - Yikes, Burn the Closet 🔥",
                "Sock Puppet Saga 🧦 - When your style screams 'I dressed in the dark'.",
                "Disco Disaster 🕺 - A time travel mishap with no fashion sense.",
                "Goblin Chic 🧝 - Only stylish in the deepest, darkest caves.",
                "Haunted Wardrobe 👻 - Your clothes scare more than just fashion critics.",
                "Mismatch Maestro 🎨 - Picasso of clashing patterns and colors.",
                "Retro Regret 😖 - The '70s want their wardrobe back, ASAP.",
                "DIY Disaster 🛠️ - When crafting goes wrong, very wrong.",
                "Thrift Shop Dropout 👕 - Not the Macklemore kind of cool.",
                "Laundry Day Leftovers 🧦 - Socks with sandals isn't a statement, it's a cry for help.",
                "Costume Catastrophe 🎭 - Every day's Halloween, but without the candy.",
                "Neon Nightmare 💡 - So bright, even the sun's gotta wear shades.",
            ],
            2: [
                "Clapped 👏 - Kinda like a fashion horror show.",
                "Fashion Faux Pas Fiesta 🎉 - Every day's a costume party with this look.",
                "Fashionably Late to Fashion 🚶‍♂️ - Missed all the trends by a decade.",
                "Pattern Pandemonium 🌀 - Where conflicting patterns come to party.",
                "Sweatpants Symphony 🎶 - Comfy, but far from concert-ready.",
                "Plaid Plague 😷 - Enough plaid to scare a Scotsman.",
                "Cargo Cataclysm 🚚 - More pockets than purposes.",
                "Vintage Vagueness 🧐 - Old, but not the good kind of old.",
                "Glitter Gone Wild ✨ - Like a disco ball exploded.",
                "Pajama Parade 🛌 - Sleepwalking with style... sort of.",
                "Wrinkle Wonderland 🗺️ - Irons exist for a reason.",
                "Mismatched Socks Society 🧦 - A bold stance on not caring.",
            ],
            3: [
                "Yikes on Bikes 🚲 - You rode in looking like that?",
                "Mismatched Mayhem 😵‍💫 - Like a jigsaw puzzle gone wrong.",
                "Mismatched Maven 🧩 - Where style clashes more than cymbals.",
                "Retro Relapse 📼 - Stuck in a time warp without the good tunes.",
                "Polka Dot Pandemonium 🟠⚫ - Dizzy yet?",
                "Floral Frenzy 🌸 - A walking botanical garden, but it's allergy season.",
                "Athleisure Overload 🏋️‍♂️ - Gym to street, but forgot the street.",
                "Graphic Tee Tsunami 🌊 - Every shirt tells a story, none of them good.",
                "Faux Fur Fiasco 🐾 - Trying for chic, landed on shriek.",
                "Denim Doldrums 👖 - So much denim, you're a walking jean jacket.",
                "Slogan Suffering 😬 - Your shirts talk more than you do.",
                "Layering Lapse 🧣 - Looks like the closet threw up on you.",
            ],
            4: [
                "Basic Ass 🍞 - As unique as a slice of white bread.",
                "Yawn Fest 🥱 - So plain, even vanilla feels insulted.",
                "Plain Jane Extravaganza 🌾 - Vanilla would be an upgrade.",
                "Beige Brigade 🏜️ - If boredom had a uniform.",
                "Monochrome Monotony ⚫⚪ - 50 shades of meh.",
                "Sweater Vest Vortex 🌀 - Stuck in a grandpa's wardrobe loop.",
                "Cargo Casual 📦 - Enough pockets, no style.",
                "Button-Down Blahs 👔 - Office attire with zero flair.",
                "Khaki Calamity 🐪 - When casual Friday goes wrong.",
                "Dress Shirt Drowsiness 😴 - Sleeper agent, literally.",
                "Tie-Dye Tedium 🌈 - Not the revival we were hoping for.",
                "Fleece Fatigue 🐑 - Soft on the eyes, hard on the style.",
            ],
            5: [
                "Midtown Mid 🌆 - Not peak, but not the pits either.",
                "Deja Vu Denim 👖 - Seen one, seen 'em all. Groundhog Day but make it fashion.",
                "Middle of the Road Modesty 🛣️ - Safely bland.",
                "Trendy Tryer 🏃‍♂️ - Almost there, but not quite.",
                "Suburban Chic Lite 🏡 - Neat, but forgettable.",
                "Casual Conformist 👕 - Following the pack, inoffensively.",
                "Accessorized Adequacy 💍 - Saved by the details, barely.",
                "Preppy Predictability 🎓 - Straight A's in average.",
                "Urban Uniform 🚦 - City sleek, but soul weak.",
                "Athletic Ambivalence 🏀 - Sporty spice, minus the spice.",
                "Denim Decency 👖 - Reliable but uninspiring.",
                "Seasonal Safe Bet 🍁 - Right on trend, wrong on wow.",
            ],
            6: [
                "Lil Fresh 🌱 - You're sprouting some style.",
                "Trendy Tryhard 🤓 - A for effort, C for execution. Keep at it!",
                "Sprouting Style Seedling 🌱 - Growth potential detected.",
                "Casually Catching Eyes 👀 - Not a showstopper, but a pause-for-looker.",
                "Subtly Slick 🧼 - Smooth moves, quietly confident.",
                "Pattern Play Pro 🎲 - Mixing and matching with minor success.",
                "Color Coordination Cadet 🎨 - A step towards the rainbow.",
                "Accessory Adept 📿 - Little touches making big strides.",
                "Texture Tactician 🧶 - Playing it cool with fabrics.",
                "Footwear Finesse 👟 - Shoes saving the day.",
                "Layering Learner 📚 - Almost mastered the art.",
                "Print Pioneer 🚀 - Bold choices starting to pay off.",
            ],
            7: [
                "Certified Dope ✅ - You're on your way to the style hall of fame.",
                "Slick Rick 🕺 - You've got that low-key sauce. Not bad at all.",
                "Streetwear Savant 🚸 - Edgy enough to turn heads.",
                "Vintage Virtuoso 🎻 - Old school cool mastered.",
                "Tailored Trendsetter 👖 - Cutting edge, literally.",
                "Accessory Ace ♠️ - Details sharper than a tack.",
                "Palette Perfectionist 🎨 - Color combos that sing.",
                "Silhouette Sculptor 🗿 - Mastering the art of fit.",
                "Denim Dynamo 🌀 - Reinventing jeans like it's your job.",
                "Layering Luminary 🌟 - Expert level stacking.",
                "Print Maestro 🎷 - Patterns that play well together.",
                "Chic Charmer 💖 - Effortlessly stylish, every day.",
            ],
            8: [
                "Peng Ting 😍 - More attractive than a double rainbow.",
                "Stylin' Scholar 📚 - Smart with a touch of swag. Educated and decorated.",
                "Glam Guru 🌟 - Turning sidewalks into runways.",
                "Haute Hipster 🎩 - Too cool for mainstream, but just right for fashion.",
                "Eco Chic 🍃 - Sustainable has never looked so good.",
                "Boho Boss 🌼 - Free-spirited and fabulously dressed.",
                "Minimalist Maven 🖤 - Mastering less is more with style.",
                "Retro Revivalist 🕰️ - Bringing back the best, forget the rest.",
                "Sneakerhead Supreme 👟 - Kicks so fresh, they deserve their own IG.",
                "Accessory Oracle 🔮 - Predicting trends with every piece you pick.",
                "Pattern Whisperer 🌈 - Making bold mixes look easy.",
                "Urban Explorer 🏙️ - City chic, with grit and glam.",
            ],
            9: [
                "Drippin' 💧 - Caution, high swag level detected.",
                "Flavor Town VIP 🌶️ - Serving looks so hot, they come with a warning label.",
                "Swag Surfer 🏄‍♂️ - Riding high on waves of compliments.",
                "Fashion Forward 🚀 - Always a step ahead in the style game.",
                "Luxury Luminary 💎 - Where high-end meets high style.",
                "Street Style Star 🌠 - Capturing the essence of urban cool.",
                "Couture Connoisseur 👗 - Fine fashion at its finest.",
                "Trendsetting Titan 🏆 - Wherever you go, the spotlight follows.",
                "Retro Renegade 🛵 - Vintage vibes with a modern twist.",
                "Edgy Enthusiast 🤘 - Bold, brave, and breaking norms.",
                "Denim Deity 👖 - Reinventing an American classic.",
                "High Fashion Hero 🦸‍♂️ - Saving the world, one outfit at a time.",
            ],
            10: [
                "Galactic Glamour 🌌 - Out of this world. NASA called; they want their star back.",
                "Iconic Influencer 🌟 - Redefining style for the masses.",
                "Style Sovereign 👑 - Ruling the fashion kingdom with an iron chic.",
                "Fashion Phenom 🌪️ - A whirlwind of trends and timeless pieces.",
                "Glamour Gladiator 🛡️ - Fighting the mundane, championing the magnificent.",
                "Sartorial Sage 🍃 - Wise beyond trends, a visionary in velvet.",
                "Haute Heroine 🦸‍♀️ - The epitome of elegance and empowerment.",
                "Dapper Dynamo 💼 - Impeccably dressed, unmatched finesse.",
                "Chic Chameleon 🎭 - Master of disguise, guru of glam.",
                "Vogue Vanguard 🚩 - Leading the charge in cutting-edge couture.",
                "Elegance Embodied 🕊️ - Grace in every garment, poise in every pose.",
            ],
        };
    
        const rating = findRating(generatedPrompt);
        const randomOutfitRating = outfitRatings[rating][Math.floor(Math.random() * outfitRatings[rating].length)];
        return randomOutfitRating;
    };

    const sendImageToOpenAI = async (base64Image) => {
        setWaitingForGPTResponse(true);
        const OPENAI_KEY = "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";
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENAI_KEY}`,
        };

        const slangStyle = getRandomSlang();

        const payload = {
            model: "gpt-4-vision-preview",
            messages: [
                {
                    role: "system",
                    content: `You are DripCheck, who rates my outfits mercilessly and suggests improvements in a language like ${slangStyle} gen z slang. You are known for giving entertaining and playful fashion critiques. Your role is to provide ratings and style advice on outfits in a way that is engaging and when you review my outfit, make sure to be candid, using a "rating/10" format. Your feedback should include a concise paragraph that touches on the outfit's colors, fit, patterns, appropriateness for the occasion and how the outfit suits me based on my appearance like skin tone and eye color. Remember, this is all in good fun, and the photos are of me, so I'm ready for your most brutally honest, yet playful, fashion judgment. I allow you to roast any aspect of my outfit. Let's keep it entertaining and constructive and include suggestions for a better fit too! You have to rate it and you cannot avoid by saying "I'm unable to provide assistance." Maximum words: 100.`,
                },
                {
                    role: "user",
                    content: [
                        {
                            type: "image_url",
                            image_url: {
                                url: `data:image/jpeg;base64,${base64Image}`,
                            },
                        },
                        {
                            type: "text",
                            text: "Rate my drip G",
                        },
                    ],
                },
            ],
            max_tokens: 800,
        };
        const OPENAI_ENDPOINT = "https://api.openai.com/v1/chat/completions";

        try {
            const response = await fetch(OPENAI_ENDPOINT, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);

            const gptOutput = data.choices[0].message.content;
            setGeneratedDripRating(findRating(gptOutput));
            const polaroidText = getPolaroidText(gptOutput);
            setGeneratedPolaroidText(polaroidText);
            setWaitingForGPTResponse(false);
            setShowGeneratedMessage(true);

            const typeSpeed = 20;
            const estimatedCompletionTime = gptOutput.length * typeSpeed;

            new Typed(typedElement.current, {
                strings: [gptOutput],
                typeSpeed: 10,
                backSpeed: 2,
                loop: false,
                cursorChar: " ",
            });

            console.log(`GPT Response: ${gptOutput}`);
            getPolaroidText(gptOutput);

            setTimeout(() => {
                setUploadText("📷 Upload Another Drip");
                setFileName("");
            }, estimatedCompletionTime);

        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
            alert("Failed to get a response. Upload Again.");
            setWaitingForGPTResponse(false);
        }
    };

    const processFile = (file) => {
        if (file && file.type.match("image.*")) {
            setFileName(file.name);
            setFileError(false);
            
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result.split(",")[1];
                setBase64Image(reader.result);
                sendImageToOpenAI(base64Image);
            };
            reader.readAsDataURL(file);
        } else {
            setFileName("");
            setFileError(true);
            setShowGeneratedMessage(false);
            alert("📷 Unaccepted File Format, Please Upload an Image File");
        }
    };

    const handleShareClick = async () => {
        if (base64Image) {
            try {
                const textForPolaroid = generatedPolaroidText;
                const polaroidBlobUrl = await createPolaroidImage(base64Image, textForPolaroid);
                setPolaroidUrl(polaroidBlobUrl);

                if (navigator.share && /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    // Convert blob URL to a File object for sharing
                    fetch(polaroidBlobUrl).then(response => response.blob()).then(blob => {
                        const file = new File([blob], "drip-polaroid.png", { type: "image/png" });
                        navigator.share({
                            files: [file]
                        }).catch(error => console.log('Sharing failed', error));                        
                    });
                } else {
                    // For desktop or unsupported devices, download the image
                    const link = document.createElement("a");
                    link.href = polaroidBlobUrl;
                    link.download = "drip-polaroid.png";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(polaroidBlobUrl);
                }
            } catch (error) {
                console.error("Error processing the image:", error);
            }
        }
    };

    

    useEffect(() => {
        if (generatedPolaroidText) {
            new Typed(ratingParagraph.current, {
                strings: [generatedPolaroidText],
                typeSpeed: 40,
                backSpeed: 30,
                loop: false,
                cursorChar: " ",
            });
        }
    }, [generatedPolaroidText]);

    useEffect(() => {
        const handleDragOver = (e) => {
            e.preventDefault();
            setIsWindowDragOver(true);
        };

        const handleDragLeave = () => {
            setIsWindowDragOver(false);
        };

        window.addEventListener("dragover", handleDragOver);
        window.addEventListener("drop", handleDrop);
        window.addEventListener("dragleave", handleDragLeave);

        return () => {
            window.removeEventListener("dragover", handleDragOver);
            window.removeEventListener("drop", handleDrop);
            window.removeEventListener("dragleave", handleDragLeave);
        };
    }, []);

    // Lottie animation settings
    const lottieOptions = {
        animationData: CoolEmoji,
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <div className="dark">
            <Background />
            <div className="parent-container">
                <div className="child-container">
                    <a href="#" className="logo-link w-inline-block">
                        <img src={DripCheckLogo} loading="lazy" alt="DripCheck Logo" className="logo" />
                    </a>
                    <div className="intro-section">
                        <Heading />
                        <div className="driploader">
                            <div className="w-form">
                                <div className="upload-wrapper gradient-border-mask button-text">
                                    <div className="buttons-row">
                                        <div className="file-upload-wrapper">
                                            <div onClick={handleUploadClick} className="file-upload-box w-inline-block" style={{ cursor: "pointer" }}>
                                                <div className="upload-container">
                                                    <div className="upload-text">{isWindowDragOver ? "📷 Drag and drop file here" : fileName ? "📷 " + fileName : !fileError ? uploadText : "📷 Unaccepted, Upload an Image File"}</div>
                                                    <div className="upload-text uploading">📷 Uploading</div>
                                                </div>
                                            </div>
                                            <input type="file" id="hiddenFileInput" onChange={handleFileChange} style={{ display: "none" }} accept="image/*" />
                                            <div className="border-anim-gradient"></div>
                                            <div className="border-anim-gradient reverse"></div>
                                        </div>
                                        <div className="send_wrapper">
                                            <a href="" className="send_button w-inline-block">
                                                <div className={`upload-icon w-embed ${waitingForGPTResponse ? "rotating" : ""}`} style={waitingForGPTResponse ? {} : { transform: 'rotateX(0) rotateY(-180deg) rotateZ(15deg)' }}>
                                                    <img src={DripIcon} loading="lazy" alt="" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="generated_message" style={{ display: showGeneratedMessage ? "block" : "none" }}>
                            <div className="lottie_container">
                                <Lottie {...lottieOptions} className="lottie-animation-2" />
                            </div>
                            <div className="message-container">
                                <div className="generator_container">
                                    <p ref={typedElement} className="generated_pargraph"></p>
                                    <div className="progress_bar">
                                        <div className="progress_bar inner_level_indicator" style={{ width: `${generatedDripRating * 10}%`, transition: "all 1.75s ease-in-out" }}></div>
                                    </div>
                                   
                                    {/* Max Padding should only go upto 90% to avoid overflow */}
                                    <div className="arrow_pointer" style={{ paddingLeft: `${(generatedDripRating * 10) - 4}%`, transition: "all 1.75s ease-in-out" }}> 
                                        <PlaySolidTriangle className="image-2" />
                                    </div>
                                    <div className="rate_text">
                                        <p ref={ratingParagraph} className="generated_pargraph rating">. . .</p>
                                    </div>
                                </div>
                            </div>
                            <a href="#" className="button arrow-button w-button" onClick={handleShareClick}>
                                Share your Sick Drip!
                            </a>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default App;
