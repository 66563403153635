import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#888b8c"
      height="100%"
      version="1.1"
      viewBox="0 0 369.903 271.849"
    >
      <path
        strokeWidth="0.708"
        d="M31.344 220.17c-6.442 10.477-6.655 23.645-.637 34.334a34.044 34.044 0 0029.662 17.345h249.195a34.044 34.044 0 0029.663-17.345c6.017-10.69 5.734-23.857-.637-34.335L213.992 16.283C207.833 6.159 196.86 0 184.967 0c-11.894 0-22.796 6.16-29.026 16.283z"
      ></path>
      <path
        strokeWidth="0.708"
        d="M47.035 194.558L0 271.747l83.634.076z"
      ></path>
      <path
        strokeWidth="0.708"
        d="M322.868 194.575l47.035 77.19-83.634.076z"
      ></path>
    </svg>
  );
}

export default Icon;
