import React from "react";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer--text">
        All Rights Reserved - © 2024&nbsp;<a target="_blank" href="https://incybot.github.io/">Sparsh</a> and  <a target="_blank" href="https://manasbam.com">Manas</a>.<br />    
        Privacy notice:&nbsp;&nbsp;We do not store any user&#x27;s data or images.
      </div>
    </div>
  );
}

export default Footer;
